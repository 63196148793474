/* eslint-disable react/destructuring-assignment */
import clsx from 'clsx';
import RefillEmptyIcon from 'public/icons/medium/icon-medium-refill-empty.svg';
import RefillFullIcon from 'public/icons/medium/icon-medium-refill-full.svg';
import $typography from 'src/styles/typography.module.scss';

import { NakedButton } from '@components-design-system/button';
import { IDS } from '@constants/elementIds';

import $refillCartIconButton from './RefillCartIconButton.module.scss';

type RefillCartIconButtonProps = {
    count: number;
    onClick: () => void;
};

export function RefillCartIconButton(props: RefillCartIconButtonProps) {
    return (
        <NakedButton
            onClick={props.onClick}
            className={$refillCartIconButton.button}
            id={IDS.REFILL_CART_ICON_BUTTON}
            title="Open your refill"
        >
            {props.count > 0 ? <RefillFullIcon /> : <RefillEmptyIcon />}

            <span
                className={clsx(
                    $typography.body3bold,
                    $refillCartIconButton.text,
                )}
            >
                {props.count}
            </span>
        </NakedButton>
    );
}
