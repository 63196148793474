import clsx from 'clsx';
import Link from 'next/link';

import { NakedButton } from '@components-design-system/button';
import { Icon } from '@components-design-system/icon';
import { Text } from '@components-design-system/text';
import { AnnouncementsBadge } from '@components/AnnouncementsBadge';
import { Cue } from '@components/Cue';
import { Overlay } from '@components/Overlay';
import { IDS } from '@constants/elementIds';
import { RefillCartIconButton } from '@scenes/Dashboard/Refills/components/RefillCartIconButton';
import $typography from '@styles/typography.module.scss';
import { CueName } from '@type/cues';

import $mobileHeaderBase from './mobileHeaderBase.module.scss';

export const MobileHeaderBaseHeight = $mobileHeaderBase.containerHeight;

interface OverlayContentProps {
    text: string;
}

const OverlayContent = ({ text }: OverlayContentProps) => <span>{text}</span>;

interface MobileHeaderBaseProps {
    cartCount: number;
    hasUnreadAnnouncements: boolean;
    isAnnouncementsInboxEnabled: boolean;
    handleDrawerToggle: () => void;
    onCartClick: () => void;
    openAnnouncementInbox: () => void;
    pageTitle?: string;
    pageTitlePopoverText?: string;
    isVisible: boolean;
}

const MobileHeaderBase = ({
    cartCount,
    hasUnreadAnnouncements,
    handleDrawerToggle,
    isAnnouncementsInboxEnabled,
    onCartClick,
    openAnnouncementInbox,
    pageTitle,
    pageTitlePopoverText,
    isVisible,
}: MobileHeaderBaseProps) => {
    return (
        <div
            className={clsx($mobileHeaderBase.container, {
                [$mobileHeaderBase.hidden]: !isVisible,
                [$mobileHeaderBase.visible]: isVisible,
            })}
        >
            <div className={$mobileHeaderBase.menuButtonContainer}>
                <NakedButton
                    aria-label="open drawer"
                    data-testid="open-drawer-button"
                    className={clsx(
                        $mobileHeaderBase.menuButton,
                        hasUnreadAnnouncements &&
                            $mobileHeaderBase.menuButtonUnread,
                    )}
                    onClick={handleDrawerToggle}
                >
                    <Icon variant="smallMenu" />
                </NakedButton>
            </div>

            <div className={$mobileHeaderBase.pageTitleContainer}>
                {pageTitle ? (
                    <div>
                        {pageTitlePopoverText ? (
                            <Overlay
                                content={
                                    <OverlayContent
                                        text={pageTitlePopoverText}
                                    />
                                }
                                id="mobile-header-popover"
                                popoverPlacement="bottom"
                            >
                                <NakedButton onClick={() => null}>
                                    <Text
                                        className={clsx(
                                            $typography.unselectable,
                                            $mobileHeaderBase.pageTitle,
                                        )}
                                        textClassName="headline4"
                                    >
                                        {pageTitle}
                                    </Text>
                                </NakedButton>
                            </Overlay>
                        ) : (
                            <Text
                                className={clsx(
                                    $typography.unselectable,
                                    $mobileHeaderBase.pageTitle,
                                )}
                                textClassName="headline4"
                            >
                                {pageTitle}
                            </Text>
                        )}
                    </div>
                ) : (
                    <Link href="/">
                        <img
                            alt="The Rounds Logo"
                            height="32"
                            src="/images/the_rounds_logo.svg"
                        />
                    </Link>
                )}
            </div>

            <div className={$mobileHeaderBase.refillButtonContainer}>
                {isAnnouncementsInboxEnabled && (
                    <>
                        <Cue
                            anchorSelector={`#${IDS.ANNOUNCEMENTS_ICON_BUTTON}`}
                            copy="Never miss a beat—click here to catch all the latest updates and announcements!"
                            name={CueName.FirstAnnouncementsIconView}
                            position="anchor"
                        />
                        <AnnouncementsBadge
                            onClick={openAnnouncementInbox}
                            unread={hasUnreadAnnouncements}
                        />
                    </>
                )}
                <Cue
                    anchorSelector={`#${IDS.REFILL_CART_ICON_BUTTON}`}
                    copy="When you add a product one time, it will show up here. Click the bag to see what’s in your upcoming delivery."
                    name={CueName.FirstOneTimeAdd}
                    position="anchor"
                />
                <RefillCartIconButton count={cartCount} onClick={onCartClick} />
            </div>
        </div>
    );
};

export { MobileHeaderBase };
