import { useRouter } from 'next/router';
import { useFeatureFlagVariantKey as useFeatureFlagVariantKeyRemote } from 'posthog-js/react';
import { useEffect, useRef, useState } from 'react';

/*
 * This hook is a wrapper around the useFeatureFlagVariantKey hook from posthog-js/react library.
 * It ensures a default value is provided if our feature flagging provider (currently Posthog)
 * fails to return a value.
 *
 * This implementation addresses a specific scenario where the remote feature flag is
 * unavailable after a certain period of time, such as when an ad blocker prevents our feature
 * flagging provider from loading.
 *
 * Additionally, it allows us to override the value returned by our feature flagging provider
 * using query parameters; our feature flagging provider will be overridden if the URL query
 * params include "?disable_{FEATURE_FLAG_NAME}=true".
 *
 * Example usage:
 *
 * const exampleFeatureFlagEnabled = useFeatureFlagVariantKey(
 *    FLAGS.EXAMPLE.id,
 *    false
 * );
 */

const DEFAULT_FEATURE_FLAG_TIMEOUT = 5000;

const useFeatureFlagVariantKey = (
    featureFlag: string,
    defaultValue?: string | undefined,
    delay = DEFAULT_FEATURE_FLAG_TIMEOUT,
): boolean | string | undefined => {
    const { query } = useRouter();

    const [internalValue, setInternalValue] = useState<
        boolean | string | undefined
    >(undefined);

    const remoteValue = useFeatureFlagVariantKeyRemote(featureFlag);

    const timerRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (!timerRef.current) {
            timerRef.current = setTimeout(() => {
                if (remoteValue === undefined) {
                    setInternalValue(defaultValue);
                }
            }, delay);
        }

        return () => {
            clearTimeout(timerRef.current);
            timerRef.current = undefined;
        };
    }, [defaultValue, delay, remoteValue]);

    if (query && query[`disable_${featureFlag}`] === 'true') {
        return 'control';
    }

    if (query && !!query[`${featureFlag}`]) {
        if (!query[`${featureFlag}`]) return defaultValue;
        if (Array.isArray(query[`${featureFlag}`])) {
            const ffParam = query[`${featureFlag}`] as string[];
            return ffParam[0];
        }
        return query[`${featureFlag}`] as string;
    }
    return internalValue !== undefined ? internalValue : remoteValue;
};

export { useFeatureFlagVariantKey };
