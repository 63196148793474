// TODO: Centralize what it means to be an essential building (another definition can be found in PostHog: https://us.posthog.com/project/3662/feature_flags/69519)
const essentialBuildingIds = [
    78, // Avec on H
    1069, // The Melwood
    1115, // Banner Lane
    1124, // Camden NoMa
    1126, // Camden Grand Parc
    1127, // Camden Roosevelt
    1125, // Camden South Capitol
    1128, // Camden Potomac Yard
    457, // Collection 14
    504, // J. Linea
    985, // Link on H Street
    1130, // Camden NoMa (ii)
    1135, // Banner Lane II
    10, // Estate at The Yards
    11, // Guild Lofts
    12, // Twelve12
    13, // Foundry Lofts
    1133, // Vela
    1148, // Modera Clarendon
    1166, // Finley at Fairfax Corner
    1164, // Westlight
    1165, // Capitol Rose
    921, // Indigo 301
    1073, // ANOVA uCity Square
    848, // The Oliver Chamblee (ATL)
    1012, // 1133 On The Square (ATL)
    1168, // Amorance (ATL)
];

export const isEssentialBuilding = (buildingId: number) =>
    essentialBuildingIds.includes(buildingId);
