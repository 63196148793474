export enum CueName {
    PLPProductFrequency = 'plp_product_frequency',
    FirstOneTimeAdd = 'first_one_time_add',
    FirstAutopilotAdd = 'first_autopilot_add',
    FirstPQVOpen = 'first_product_quick_view_open',
    FirstAnnouncementsIconView = 'announcements_inbox',
}

export type CueStatus = 'indeterminate' | 'unseen' | 'visible' | 'seen';

export type CueOffset = Partial<{
    top: number;
    bottom: number;
    left: number;
    right: number;
}>;
