import clsx from 'clsx';

import { Icon } from '@components-design-system/icon';

import $mobileHeaderPaymentCalloutBannerCollapsed from './paymentCalloutBannerCollapsed.module.scss';

export interface MobileHeaderPaymentCalloutBannerCollapsedProps {
    deliveryDayOfWeek: string;
    lockTimeDayOfWeek: string;
    onClick: () => void;
    isVisible: boolean;
}

export const MobileHeaderPaymentCalloutBannerCollapsedHeight =
    $mobileHeaderPaymentCalloutBannerCollapsed.containerHeight;

const MobileHeaderPaymentCalloutBannerCollapsed = ({
    deliveryDayOfWeek,
    lockTimeDayOfWeek,
    isVisible,
    onClick,
}: MobileHeaderPaymentCalloutBannerCollapsedProps) => {
    return (
        <div
            className={clsx(
                $mobileHeaderPaymentCalloutBannerCollapsed.container,
                {
                    [$mobileHeaderPaymentCalloutBannerCollapsed.hidden]:
                        !isVisible,
                    [$mobileHeaderPaymentCalloutBannerCollapsed.visible]:
                        isVisible,
                },
            )}
            role="button"
            onClick={onClick}
            aria-label="Add payment method"
            tabIndex={0}
        >
            <span className={$mobileHeaderPaymentCalloutBannerCollapsed.text}>
                Add payment by {lockTimeDayOfWeek} for {deliveryDayOfWeek}
                &nbsp;delivery
            </span>
            <Icon variant="xsmallArrowRight" />
        </div>
    );
};

export { MobileHeaderPaymentCalloutBannerCollapsed };
