import { createTheme } from '@mui/material/styles';

import HelveticaNeueLTStdBdWoff from '../../public/fonts/HelveticaNeueLTStd-Bd.woff';
import HelveticaNeueLTStdBdWoff2 from '../../public/fonts/HelveticaNeueLTStd-Bd.woff2';
import HelveticaNeueLTStdRomanWoff from '../../public/fonts/HelveticaNeueLTStd-Roman.woff';
import HelveticaNeueLTStdRomanWoff2 from '../../public/fonts/HelveticaNeueLTStd-Roman.woff2';
import RunWildWoff from '../../public/fonts/RunWild.woff';
import RunWildWoff2 from '../../public/fonts/RunWild.woff2';
import { backgroundColorContent } from './theme.module.scss';

const TrThemeOptions = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#000000',
            dark: '#000000',
        },
        secondary: {
            main: '#ecac0a',
        },
        error: {
            main: '#ff0000',
            fontSize: 'small',
        },
        warning: {
            main: '#EEC86F',
            dark: '#D9AC42',
            light: '#F4DCA4',
        },
        dark: {
            main: '#000000',
        },
        background: {
            alternativeBG: '#EAE0D0',
            default: '#EAE0D0',
            cardBG: '#F2EDE1',
            content: backgroundColorContent,
            cream: '#ede1d1',
            baby: '#7f7e58',
            pantry: '#8595ac',
            household: '#b75820',
            personal_care: '#ecac0a',
            pets: '#9a8059',
            alert: '#e3aa1e',
        },
        color: {
            trDarkRed: '#9b0007', // e.g. TrCreditCardTextField Custom Component - Onboarding
        },
        text: {
            black: '#000',
            trGrey: '#75726C',
            error: '#ff0000',
        },
    },
    typography: {
        textColor: '#000',
        fontFamily: '"HelveticaNeueLT Std", Helvetica, Arial, sans-serif',

        body1: {
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '19.2px',
        },

        body2: {
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '19.2px',
        },

        h1: {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '28.8px',
        },
        h2: {
            fontWeight: '400',
            fontSize: '24px',
            lineHeight: '28.8px',
        },
        h3: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '19px',
        },
        h4: {
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '19px',
            opacity: 0.5,
        },
        h5: {
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '16.8px',
        },
        subtitle1: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16.8px',
        },
        subtitle2: {
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: '18px',
            letterSpacing: '0em',
            fontFamily: 'Run Wild',
            textTransform: 'uppercase',
            width: '100%',
            textAlign: 'center',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    fontSize: '.7rem',
                    fontWeight: '500',
                    letterSpacing: '.08rem',
                    padding: '.7rem 2rem',
                },
            },
            variants: [
                {
                    props: { variant: 'dark' },
                    style: {
                        backgroundColor: 'rgb(0,0,0,1)',
                        border: 'none',
                        color: '#FFF',
                        ':hover': {
                            backgroundColor: 'rgb(0,0,0,.7)',
                        },
                        ':focus': {
                            backgroundColor: 'rgb(0,0,0,.8)',
                        },
                        ':disabled': {
                            backgroundColor: 'rgb(0,0,0,.2)',
                        },
                    },
                },
            ],
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Run Wild';
                    src: local('Run Wild'),
                        url(${RunWildWoff2}) format('woff2'),
                        url(${RunWildWoff}) format('woff');
                    font-weight: normal;
                    font-style: normal;
                    font-display: swap;
                }
                /**
                 * @license
                 * MyFonts Webfont Build ID 4098477, 2021-06-04T16:43:21-0400
                 *
                 * The fonts listed in this notice are subject to the End User License
                 * Agreement(s) entered into by the website owner. All other parties are
                 * explicitly restricted from using the Licensed Webfonts(s).
                 *
                 * You may obtain a valid license at the URLs below.
                 *
                 * Webfont: HelveticaNeueLTStd-Roman by Linotype
                 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-55-roman-189171/
                 *
                 * Webfont: HelveticaNeueLTStd-Bd by Linotype
                 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-75-bold-189171/
                 *
                 *
                 * Webfonts copyright: Copyright &amp;#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
                 *
                 * © 2021 MyFonts Inc
                */
                @import url("//hello.myfonts.net/count/3e89ad");
                @font-face {
                    font-family: 'HelveticaNeueLT Std';
                    src: url(${HelveticaNeueLTStdRomanWoff2}) format('woff2'),
                        url(${HelveticaNeueLTStdRomanWoff}) format('woff');
                    font-weight: normal;
                    font-style: normal;
                    font-display: swap;
                }

                @font-face {
                    font-family: 'HelveticaNeueLT Std';
                    src: url(${HelveticaNeueLTStdBdWoff2}) format('woff2'),
                        url(${HelveticaNeueLTStdBdWoff}) format('woff');
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                }
          `,
        },
        stripeCard: {
            fontSize: '16px',
            lineHeight: '24px',
            textColor: '#000',
        },
    },
    // https://mui.com/material-ui/customization/breakpoints/
    breakpoints: {
        xs: '0px',
        sm: '600px',
        md: '900px',
        lg: '1200px',
        xl: '1536px',
    },
    extraSmallDown: 'max-width: 600px',
    smallUp: 'min-width: 600px',
    smallDown: 'max-width: 900px',
    smallOnly: 'max-width: 900px) and (min-width: 600px',
    mediumDown: 'max-width: 1200px',
    mediumUp: 'min-width: 900px',
    mediumOnly: 'max-width: 1200px) and (min-width: 900px',
    largeUp: 'min-width: 1536px',
});

// eslint-disable-next-line import/no-default-export
export default TrThemeOptions;
