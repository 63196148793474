import { MobileHeaderSubComponentName } from '@scenes/Dashboard/components/mobileHeader/mobileHeader.types';

const allSubComponents = [
    MobileHeaderSubComponentName.Base,
    MobileHeaderSubComponentName.SearchWithPrompts,
    MobileHeaderSubComponentName.SearchResults,
    MobileHeaderSubComponentName.Search,
    MobileHeaderSubComponentName.IconCategoryNavigation,
    MobileHeaderSubComponentName.TextCategoryNavigation,
    MobileHeaderSubComponentName.PaymentCalloutBannerCollapsed,
];

const componentsToHideOnSearch = [
    MobileHeaderSubComponentName.IconCategoryNavigation,
    MobileHeaderSubComponentName.TextCategoryNavigation,
];

const getMobileHeaderSubComponentsAtTopOfPage = (page: string) => {
    switch (true) {
        case page === '/discover':
        case page.includes('inventory'):
        case page.includes('collection'):
        case page === '/search':
            return [
                MobileHeaderSubComponentName.Base,
                MobileHeaderSubComponentName.SearchWithPrompts,
                MobileHeaderSubComponentName.IconCategoryNavigation,
            ];
        // All other pages
        default:
            return [
                MobileHeaderSubComponentName.Base,
                MobileHeaderSubComponentName.SearchWithPrompts,
            ];
    }
};

const getMobileHeaderSubComponentsOnDownScroll = (
    hasEnteredPaymentDetails: boolean,
    isUpdatePaymentMethodStickyButtonEnabled: boolean,
) => {
    switch (true) {
        case !hasEnteredPaymentDetails &&
            !isUpdatePaymentMethodStickyButtonEnabled:
            return [
                MobileHeaderSubComponentName.SearchWithPrompts,
                MobileHeaderSubComponentName.PaymentCalloutBannerCollapsed,
            ];
        // All other pages
        default:
            return [MobileHeaderSubComponentName.SearchWithPrompts];
    }
};

const getMobileHeaderSubComponentsOnUpScroll = (page: string) => {
    switch (true) {
        case page === '/discover':
        case page.includes('inventory'):
        case page.includes('collection'):
        case page === '/search':
            return [
                MobileHeaderSubComponentName.Base,
                MobileHeaderSubComponentName.SearchWithPrompts,
                MobileHeaderSubComponentName.TextCategoryNavigation,
            ];
        // All other pages
        default:
            return [
                MobileHeaderSubComponentName.Base,
                MobileHeaderSubComponentName.SearchWithPrompts,
            ];
    }
};

export {
    allSubComponents,
    componentsToHideOnSearch,
    getMobileHeaderSubComponentsAtTopOfPage,
    getMobileHeaderSubComponentsOnDownScroll,
    getMobileHeaderSubComponentsOnUpScroll,
};
