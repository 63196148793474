import { useQuery } from '@apollo/client';
import { noop } from 'lodash';
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
} from 'react';

import { useAuthContext } from '@contexts/AuthContext';
import { useAutopilotContext } from '@contexts/AutopilotContext';
import { REFILLS_QUERY } from '@queries/refill';
import { nowDate } from '@utilities/date';

import {
    getMapOfSevIdsToActivatedPromotionalCoupons,
    mapOrderIntents,
} from './transformers';
import { PromotionalCouponWithDiscount, RefillsOrderIntent } from './types';

function noopPromise() {
    return new Promise((resolve) => {
        noop();
        resolve(undefined);
    });
}

export type RefillContextShape =
    | {
          state: 'loading';
          loading: true;
          refresh: () => Promise<unknown>;
      }
    | {
          state: 'incomplete';
          loading: false;
          refresh: () => Promise<unknown>;
      }
    | {
          state: 'complete';
          orderIntents: RefillsOrderIntent[];
          now: RefillsOrderIntent;
          loading: boolean;
          getOrderIntentById: (
              orderIntentId: number,
          ) => RefillsOrderIntent | undefined;
          getOrderIntentByDeliveryDate: (
              deliveryDate: string,
          ) => RefillsOrderIntent | undefined;
          findProductInNextRefill: (
              sellableEntityVariationId: number,
          ) => RefillsOrderIntent['products'][0] | undefined;
          findSoonestOrderIntentWithProduct: (
              sellableEntityVariationId: number,
          ) => RefillsOrderIntent | undefined;
          findActivatedCouponForProductInNextRefill: (
              sellableEntityVariationId: number,
          ) => PromotionalCouponWithDiscount | undefined;
          refresh: () => Promise<unknown>;
      };

const emptyDefault: RefillContextShape = {
    state: 'loading',
    loading: true,
    refresh: noopPromise,
};

const RefillContext = createContext<RefillContextShape>(emptyDefault);

const useRefillContext = () => useContext(RefillContext);

const RefillProvider = ({ children }: { children: ReactNode }) => {
    const { isLoggedIn } = useAuthContext();

    const refillsQuery = useQuery(REFILLS_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: { nowDate: nowDate() },
        returnPartialData: true, // This allows us to be a bit looser in our cache updates
        skip: !isLoggedIn,
    });

    const autopilotContext = useAutopilotContext();

    const orderIntents = mapOrderIntents(
        refillsQuery?.data?.orderIntents ?? [],
        autopilotContext.state === 'complete'
            ? autopilotContext.userBundleProducts
            : [],
    );

    const now =
        orderIntents.length > 0
            ? orderIntents.find((orderIntent) => !orderIntent.isLocked)
            : undefined;

    const promotionalCouponsBySevId = useMemo(() => {
        return now
            ? getMapOfSevIdsToActivatedPromotionalCoupons(now)
            : undefined;
    }, [now]);

    const getOrderIntentById = useCallback(
        (orderIntentId: number) => {
            return orderIntents.find(
                (orderIntent) => orderIntent.id === orderIntentId,
            );
        },
        [orderIntents],
    );

    const getOrderIntentByDeliveryDate = useCallback(
        (deliveryDate: string) => {
            return orderIntents.find(
                (orderIntent) =>
                    orderIntent.expectedDeliveryDate === deliveryDate,
            );
        },
        [orderIntents],
    );

    const findProductInNextRefill = useCallback(
        (sellableEntityVariationId: number) => {
            return now?.products.find(
                (product) => product.id === sellableEntityVariationId,
            );
        },
        [now],
    );

    const findSoonestOrderIntentWithProduct = useCallback(
        (sellableEntityVariationId: number) => {
            return orderIntents.find((orderIntent) =>
                orderIntent.products.some(
                    (product) => product.id === sellableEntityVariationId,
                ),
            );
        },
        [orderIntents],
    );

    const findActivatedCouponForProductInNextRefill = useCallback(
        (sellableEntityVariationId: number) => {
            return promotionalCouponsBySevId?.[sellableEntityVariationId];
        },
        [promotionalCouponsBySevId],
    );

    const contextProviderValue: RefillContextShape = useMemo(() => {
        if (!isLoggedIn || orderIntents.length === 0 || !now) {
            return {
                ...emptyDefault,
                state: 'incomplete',
                loading: false,
            };
        }
        if (
            (refillsQuery.loading || autopilotContext.loading) &&
            !orderIntents.length
        ) {
            return {
                state: 'loading',
                loading: true,
                refresh: () => {
                    const refillsRefetch = refillsQuery.refetch();
                    const autopilotRefetch = autopilotContext.refresh();
                    return Promise.all([refillsRefetch, autopilotRefetch]);
                },
            };
        }
        return {
            state: 'complete',
            orderIntents,
            now,
            getOrderIntentById,
            getOrderIntentByDeliveryDate,
            findProductInNextRefill,
            findSoonestOrderIntentWithProduct,
            findActivatedCouponForProductInNextRefill,
            loading: refillsQuery.loading || autopilotContext.loading,
            refresh: async () => {
                const refillsRefetch = refillsQuery.refetch();
                const autopilotRefetch = autopilotContext.refresh();
                return Promise.all([refillsRefetch, autopilotRefetch]);
            },
        };
    }, [
        refillsQuery,
        autopilotContext,
        isLoggedIn,
        now,
        orderIntents,
        getOrderIntentById,
        getOrderIntentByDeliveryDate,
        findProductInNextRefill,
        findSoonestOrderIntentWithProduct,
        findActivatedCouponForProductInNextRefill,
    ]);

    return (
        <RefillContext.Provider value={contextProviderValue}>
            {children}
        </RefillContext.Provider>
    );
};

export { RefillProvider, useRefillContext };
