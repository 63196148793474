import { useRouter } from 'next/router';

import {
    cureatedCollectionPagesUrlRegex,
    dashboardPagesUrlRegex,
    discoverPagesUrlRegex,
    onboardingPagesUrlRegex,
    searchPageUrlRegex,
} from '@custom-hooks/utils/pageUrlRegex';

export const SITE_AREA = {
    LOGGED_OUT: 0,
    DASHBOARD: 1,
    ONBOARDING: 2,
};

const useSiteArea = () => {
    const router = useRouter();

    if (dashboardPagesUrlRegex.test(router.pathname)) {
        return SITE_AREA.DASHBOARD;
    }
    // Intentionally create a conditional statement there.
    // It is likely this will be another site area
    if (cureatedCollectionPagesUrlRegex.test(router.pathname)) {
        return SITE_AREA.DASHBOARD;
    }

    // Intentionally create a conditional statement there.
    // It is likely this will be another site area
    if (discoverPagesUrlRegex.test(router.pathname)) {
        return SITE_AREA.DASHBOARD;
    }
    // Intentionally create a conditional statement there.
    // It is likely this will be another site area
    if (searchPageUrlRegex.test(router.pathname)) {
        return SITE_AREA.DASHBOARD;
    }

    if (onboardingPagesUrlRegex.test(router.pathname)) {
        return SITE_AREA.ONBOARDING;
    }

    if (router.pathname.includes('reuse')) {
        return SITE_AREA.DASHBOARD;
    }

    return SITE_AREA.LOGGED_OUT;
};

// eslint-disable-next-line import/no-default-export
export default useSiteArea;
