import { ReactNode } from 'react';
import { Placement } from 'react-bootstrap/esm/Overlay';

import {
    OverlayTrigger,
    Popover,
} from '@components-design-system/reactBootstrapWrappers';
import { trackOverlayOpened } from '@utilities/tracking/components/overlay/trackOverlayOpened';

type StyledOverlayProps = {
    children: JSX.Element; // React.ReactNode not supported by bootstrap-react Popover
    id: string;
    content: ReactNode;
    popoverPlacement?: Placement;
    trackingText?: string;
};

function handleToggle(isVisible: boolean, id: string, trackingText: string) {
    if (isVisible) {
        trackOverlayOpened(id, trackingText);
    }
}

export function StyledOverlay({
    children,
    id,
    content,
    popoverPlacement,
    trackingText = id,
}: StyledOverlayProps) {
    const Overlay = (
        <Popover
            id={id}
            style={{
                zIndex: 9999,
            }}
        >
            <Popover.Content>{content}</Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger="click"
            placement={popoverPlacement}
            overlay={Overlay}
            onToggle={(isVisible) => handleToggle(isVisible, id, trackingText)}
            rootClose
        >
            {children}
        </OverlayTrigger>
    );
}
