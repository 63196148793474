export type SearchedProductsFunction = (
    query: string,
    productIds?: number[],
    facets?: {
        brand?: string;
    },
) => void;

/**
 * Track event when a Member executes a search query
 * @see https://segment.com/docs/connections/spec/ecommerce/v2/#products-searched
 */
const SearchedProducts: SearchedProductsFunction = (
    query,
    productIds,
    facets,
) => {
    let referrer;
    if (sessionStorage) {
        referrer = sessionStorage.getItem('referrer') || undefined;
    }
    if (window && window.analytics) {
        window.analytics.track('Products Searched', {
            query,
            returnedProductIds: productIds,
            brandFacet: facets?.brand,
            referrer,
        });
    }
};

// eslint-disable-next-line import/no-default-export
export default SearchedProducts;
