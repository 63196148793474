const sortbyMethodTypes = {
    ALPHABETICAL: 'alphabetical',
    MOST_POPULAR: 'most_popular',
    NEW: 'recently_added',
    PRICE_HIGH: 'price_high_to_low',
    PRICE_LOW: 'price_low_to_high',
};

// eslint-disable-next-line import/no-default-export
export default sortbyMethodTypes;
