/* eslint-disable react/destructuring-assignment */
import clsx from 'clsx';

import { NakedButton } from '@components-design-system/button';
import { Icon } from '@components-design-system/icon';

import $styles from './AnnouncementsBadge.module.scss';

type AnnouncementsBadgeProps = {
    unread: boolean;
    onClick: () => void;
};

export function AnnouncementsBadge(props: AnnouncementsBadgeProps) {
    return (
        <NakedButton
            className={clsx(
                $styles.button,
                props.unread && $styles.buttonUnread,
            )}
            id="announcements-icon-button"
            onClick={props.onClick}
        >
            <Icon variant="smallAnnouncements" />
        </NakedButton>
    );
}
