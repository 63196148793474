import { OrderIntentStatus } from '@mlkmncode/common';

import type { RefillsListQuery } from '@gql/graphql';

import type { RefillType } from './types';

export function getRefillTypeByIndex(index: number): RefillType {
    switch (index) {
        case 0:
            return 'now';
        case 1:
            return 'soon';
        case 2:
        default:
            return 'later';
    }
}

export function getLabelForRefillType(refillType: RefillType) {
    return {
        now: 'Now',
        soon: 'Soon',
        later: 'Later',
    }[refillType];
}

export function isOrderIntentSkipped(status: OrderIntentStatus): boolean {
    return [
        OrderIntentStatus.USER_SKIPPED,
        OrderIntentStatus.ADMIN_SKIPPED,
        OrderIntentStatus.GLOBAL_SKIPPED,
    ].includes(status);
}

export function isOrderIntentUnskippable(
    status: OrderIntentStatus,
    lockedAt?: string,
): boolean {
    return (
        lockedAt == null &&
        [
            OrderIntentStatus.USER_SKIPPED,
            OrderIntentStatus.ADMIN_SKIPPED,
        ].includes(status)
    );
}

export function isOrderIntentEditable(
    status: OrderIntentStatus,
    lockedAt?: string,
): boolean {
    return (
        lockedAt == null &&
        ![OrderIntentStatus.DELIVERED, OrderIntentStatus.ORDER_LOCKED].includes(
            status,
        )
    );
}

export function isOrderIntentDisabled(status: OrderIntentStatus): boolean {
    return (
        isOrderIntentSkipped(status) ||
        [
            OrderIntentStatus.FAILED,
            OrderIntentStatus.CANCELED_OMITTED,
            OrderIntentStatus.PAUSED_OMITTED,
            OrderIntentStatus.BELOW_MINIMUM_OMITTED,
            OrderIntentStatus.MISSING_PAYMENT_METHOD_OMITTED,
        ].includes(status)
    );
}

export function sumOrderIntentQuantity(
    products: RefillsListQuery['orderIntents'][0]['orderIntentProducts'],
): number {
    return (products ?? [])
        .filter((product) => !product.product?.service_type)
        .reduce((total, product) => total + product.quantity, 0);
}

export function isOrderIntentEmpty(itemCount: number): boolean {
    return itemCount === 0;
}
