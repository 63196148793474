/* eslint-disable react/destructuring-assignment */
import React, { Component, ReactNode } from 'react';

import { ReactRenderingError } from '@constants/error';
import { ErrorPage } from '@scenes/ErrorPage';
import { ErrorService } from '@services/error';
import $textDeprecated from '@styles/text-deprecated.module.scss';
import { supportNumber } from '@utilities/constants';

const ErrorFallback = () => {
    return (
        <ErrorPage
            message={
                <span>
                    Our server has taken a rain check. We&apos;ll fix things and
                    get you on track ASAP.{' '}
                    <a
                        className={$textDeprecated.linkText}
                        href={`sms://${supportNumber}`}
                    >
                        Contact us in the meantime
                    </a>
                    .
                </span>
            }
            title="Oh no!"
        />
    );
};

export class ErrorBoundary extends Component<
    { children: ReactNode },
    { hasError: boolean }
> {
    constructor(props: { children: ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    override componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({ hasError: true });
        const renderingError = new ReactRenderingError(
            error.message,
            info.componentStack || '',
            error,
        );

        ErrorService.captureError(renderingError);
    }

    override render() {
        if (this.state.hasError) {
            return <ErrorFallback />;
        }
        return this.props.children;
    }
}
