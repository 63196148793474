/* eslint-disable react/destructuring-assignment */
import clsx from 'clsx';
import _orderBy from 'lodash/orderBy';
import Link from 'next/link';
import { useRouter } from 'next/router';
import AlcoholIcon from 'public/icons/large/icon-large-category-alcohol.svg';
import BabyIcon from 'public/icons/large/icon-large-category-baby.svg';
import BakeryIcon from 'public/icons/large/icon-large-category-bakery.svg';
import BeveragesIcon from 'public/icons/large/icon-large-category-beverages.svg';
import DairyIcon from 'public/icons/large/icon-large-category-dairy.svg';
import FlowersIcon from 'public/icons/large/icon-large-category-flower.svg';
import FrozenIcon from 'public/icons/large/icon-large-category-frozen.svg';
import HealthIcon from 'public/icons/large/icon-large-category-health.svg';
import HouseholdIcon from 'public/icons/large/icon-large-category-household.svg';
import PantryIcon from 'public/icons/large/icon-large-category-pantry.svg';
import PersonalIcon from 'public/icons/large/icon-large-category-personal-care.svg';
import PetIcon from 'public/icons/large/icon-large-category-pet.svg';
import ProduceIcon from 'public/icons/large/icon-large-category-produce.svg';
import ProteinIcon from 'public/icons/large/icon-large-category-protein.svg';
import SeafoodIcon from 'public/icons/large/icon-large-category-seafood.svg';
import ServiceIcon from 'public/icons/large/icon-large-category-services.svg';
import SnacksIcon from 'public/icons/large/icon-large-category-snacks.svg';
import DiscoverIcon from 'public/icons/large/icon-large-discover.svg';
import SaleIcon from 'public/icons/large/icon-large-shop-deals.svg';
import ForYouIcon from 'public/icons/large/icon-large-shop-for-you.svg';
import LocalProductIcon from 'public/icons/large/icon-large-shop-local.svg';
import NewProductIcon from 'public/icons/large/icon-large-shop-new.svg';
import { useEffect, useRef } from 'react';

import { Text } from '@components-design-system/text';
import { HorizontalScroll } from '@components/HorizontalScroll';
import { useFetchCategories } from '@custom-hooks/category';
import { CategoryV2 } from '@gql/graphql';
import { MAIN_MENU_SLUG } from '@utilities/common';
import { curatedLists } from '@utilities/constants/curatedLists';

import $styles from './CategoryLinkRow.module.scss';

const CategoryLinkData: {
    [category_name: string]: { name: string; icon: JSX.Element };
} = {
    [curatedLists.DISCOVER]: {
        name: 'Discover',
        icon: <DiscoverIcon />,
    },
    [curatedLists.FOR_YOU]: {
        name: 'For You',
        icon: <ForYouIcon />,
    },
    [curatedLists.NEW_PRODUCTS]: {
        name: 'New',
        icon: <NewProductIcon />,
    },
    [curatedLists.SALE]: {
        name: 'Deals',
        icon: <SaleIcon />,
    },
    [curatedLists.LOCAL]: {
        name: 'Local',
        icon: <LocalProductIcon />,
    },
    staples: {
        name: 'Pantry',
        icon: <PantryIcon />,
    },
    sweets: {
        name: 'Snacks',
        icon: <SnacksIcon />,
    },
    beverages: {
        name: 'Beverages',
        icon: <BeveragesIcon />,
    },
    bread: {
        name: 'Bakery',
        icon: <BakeryIcon />,
    },
    dairy: {
        name: 'Dairy',
        icon: <DairyIcon />,
    },
    frozen: {
        name: 'Frozen',
        icon: <FrozenIcon />,
    },
    seafood: {
        name: 'Seafood',
        icon: <SeafoodIcon />,
    },
    alcohol: {
        name: 'Alcohol',
        icon: <AlcoholIcon />,
    },
    produce: {
        name: 'Produce',
        icon: <ProduceIcon />,
    },
    household: {
        name: 'Household',
        icon: <HouseholdIcon />,
    },
    beauty: {
        name: 'Wellness',
        icon: <PersonalIcon />,
    },
    pet: {
        name: 'Pet',
        icon: <PetIcon />,
    },
    baby: {
        name: 'Baby',
        icon: <BabyIcon />,
    },
    health: {
        name: 'Health',
        icon: <HealthIcon />,
    },
    'plants-flowers': {
        name: 'Flowers',
        icon: <FlowersIcon />,
    },
    services: {
        name: 'Services',
        icon: <ServiceIcon />,
    },
    meat: {
        name: 'Protein',
        icon: <ProteinIcon />,
    },
};

interface CategoryLinkRowProps {
    className?: string;
    fullBleed?: boolean;
    highlightActiveCategory: boolean;
    viewStyle?: 'icon' | 'pill';
    onClick: (label: string, slug: string) => void;
}

function CategoryLinkRow(props: CategoryLinkRowProps): JSX.Element | null {
    const { category } = useFetchCategories(MAIN_MENU_SLUG);
    const { asPath } = useRouter();
    const componentRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const viewStyle = props.viewStyle ?? 'icon';

    // Scroll to the active category icon so it’s always in view
    // The timeout is needed because the active category is not rendered immediately
    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            componentRef.current
                ?.querySelector('[data-category-active="true"]')
                ?.scrollIntoView({
                    block: 'nearest', // don't scroll vertically
                    inline: 'center',
                });
        }, 600);
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [asPath, category]);

    if (!category) {
        return null;
    }

    const filteredCategories = [
        curatedLists.FOR_YOU,
        curatedLists.NEW_PRODUCTS,
        curatedLists.SALE,
        curatedLists.LOCAL,
    ];

    const categoriesToRender = _orderBy(category?.subcategories, [
        'order',
    ]) as CategoryV2[];

    const discoverLinkConfig = {
        slug: curatedLists.DISCOVER,
        path: '/discover',
    };

    const filteredCategoryCofigs = filteredCategories.map((slug: string) => {
        return {
            slug,
            path: `/dashboard/inventory/${slug}`,
        };
    });

    const categoryConfigs = categoriesToRender
        .filter(
            (categoryObject) =>
                CategoryLinkData[categoryObject.slug] &&
                categoryObject.product_count,
        )
        .map((cat) => ({
            path: `/dashboard/inventory/${cat.slug}`,
            slug: cat.slug,
        }));

    const linkConfigs = [
        discoverLinkConfig,
        ...filteredCategoryCofigs,
        ...categoryConfigs,
    ];

    const links = linkConfigs.map(({ slug, path }) => {
        const isActiveCategory = asPath.startsWith(path);

        const iconStyleComponent = (
            <div
                data-category-active={isActiveCategory}
                className={$styles.item}
                onClick={() => {
                    props.onClick?.(CategoryLinkData[slug].name, slug);
                }}
            >
                <div
                    className={clsx($styles.itemIcon, {
                        [$styles.itemIconActive]: isActiveCategory,
                        [$styles.itemIconIdle]:
                            props.highlightActiveCategory && !isActiveCategory,
                    })}
                >
                    {CategoryLinkData[slug].icon}
                </div>

                <Text
                    as="span"
                    textClassName="body2medium"
                    className={clsx($styles.itemText, {
                        [$styles.itemTextIdle]:
                            props.highlightActiveCategory && !isActiveCategory,
                    })}
                >
                    {CategoryLinkData[slug].name}
                </Text>
            </div>
        );

        const pillStyleComponent = (
            <div
                data-category-active={isActiveCategory}
                className={$styles.pillItem}
                onClick={() => {
                    props.onClick?.(CategoryLinkData[slug].name, slug);
                }}
            >
                <Text
                    as="span"
                    textClassName="body2medium"
                    className={clsx($styles.pillItemText, {
                        [$styles.pillItemTextIdle]:
                            props.highlightActiveCategory && !isActiveCategory,
                    })}
                >
                    {CategoryLinkData[slug].name}
                </Text>
            </div>
        );

        return (
            <Link key={slug} href={path}>
                {viewStyle === 'icon' ? iconStyleComponent : pillStyleComponent}
            </Link>
        );
    });

    return (
        <div className={props.className} ref={componentRef}>
            <HorizontalScroll
                disableArrowsOnMobile={true}
                fullWidth
                fullBleed={props.fullBleed}
            >
                {links}
            </HorizontalScroll>
        </div>
    );
}

export { CategoryLinkRow };
