export enum DiscoverHomeComponentClickedCollectionType {
    Module = 'module',
    Row = 'row',
    Banner = 'banner',
    FilterGroup = 'filter-group',
    CategoryLink = 'category-link',
}

export type DiscoverHomeComponentClickedFunction = (
    componentType: DiscoverHomeComponentClickedCollectionType,
    componentName: string,
    componentId: number | string,
    position: number,
) => void;

/**
 * Track event when a member clicks to view a collection page.
 * @see https://www.notion.so/Product-Collection-Clicked-23ea22b9a8af4c6ca95e0ea4592c5e35?pvs=4
 */
const DiscoverHomeComponentClicked: DiscoverHomeComponentClickedFunction = (
    componentType,
    componentName,
    componentId,
    position,
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Discover Home Component Clicked', {
            componentType,
            componentName,
            componentId,
            position,
        });
};

// eslint-disable-next-line import/no-default-export
export default DiscoverHomeComponentClicked;
