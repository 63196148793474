import { stripNonNumeric } from '@utilities/string/stripNonNumeric';

import { MobileHeaderBaseHeight } from './components/base/mobileHeaderBase';
import { CategoryNavigationCollapsedContainerHeight } from './components/categoryNavigationCollapsedContainer/categoryNavigationCollapsedContainer';
import { CategoryNavigationExpandedContainerHeight } from './components/categoryNavigationExpandedContainer/categoryNavigationExpandedContainer';
import { MobileHeaderPaymentCalloutBannerCollapsedHeight } from './components/paymentCalloutBannerCollapsed/paymentCalloutBannerCollapsed';
import { MobileHeaderSearchBarHeight } from './components/searchBar/searchBar';
import { MobileHeaderSearchBarWithPromptsHeight } from './components/searchBarWithPrompts/searchBar';
import {
    MobileHeaderSearchResultsHeight,
    MobileHeaderSearchResultsProps,
} from './components/searchResults/mobileHeaderSearchResults';

// These enums are used to enumerate the names of the different components that can be rendered in the mobile header.
// The names are used to determine which components should be rendered in the mobile header based on the current state
export enum MobileHeaderSubComponentName {
    Base = 'Base',
    Search = 'Search',
    SearchWithPrompts = 'SearchWithPrompts',
    SearchResults = 'SearchResults',
    IconCategoryNavigation = 'IconCategoryNavigation',
    TextCategoryNavigation = 'TextCategoryNavigation',
    PaymentCalloutBannerCollapsed = 'PaymentCalloutBannerCollapsed',
}

// These enums are used to define the different heights of the mobile header components. The value is
// defined in each component's scss file and exported as a constant to provide a single source of truth
// for the component's height value.
export enum MobileHeaderComponentHeight {
    Base = stripNonNumeric(MobileHeaderBaseHeight),
    Search = stripNonNumeric(MobileHeaderSearchBarHeight),
    SearchWithPrompts = stripNonNumeric(MobileHeaderSearchBarWithPromptsHeight),
    SearchResults = stripNonNumeric(MobileHeaderSearchResultsHeight),
    IconCategoryNavigation = stripNonNumeric(
        CategoryNavigationExpandedContainerHeight,
    ),
    TextCategoryNavigation = stripNonNumeric(
        CategoryNavigationCollapsedContainerHeight,
    ),
    PaymentCalloutBannerCollapsed = stripNonNumeric(
        MobileHeaderPaymentCalloutBannerCollapsedHeight,
    ),
}

export type MobileHeaderSubComponentProps = MobileHeaderSearchResultsProps;
